<template>
  <div class="row">
    <div class="col-md-12" style="background: white">
      <table class="table table-striped">
        <thead>
          <tr>
            <th colspan="3">
              <input
                type="text"
                v-model="searchInput"
                class="form-control"
                placeholder="Search User"
              />
            </th>

            <th colspan="2">
              <router-link
                :to="{ name: 'client.user.create', params: { id: 0 } }"
              >
                <div class="btn btn-info" style="float: right">
                  <i class="fa fa-plus"></i> Add User
                </div>
              </router-link>
            </th>
          </tr>

          <tr>
            <th>Name</th>
            <th>Username</th>
            <th>Role</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody v-if="!loadingData && filteredUsers.length > 0">
          <tr v-for="(item, index) in filteredUsers" :key="index">
            <td>{{ item.name }}</td>
            <td>{{ item.username }}</td>
            <td>{{ item.roles }}</td>
            <td>
              <i class="fa fa-times text-danger" v-if="!item.is_active"></i>
              <i class="fa fa-check text-success" v-if="item.is_active"></i>
            </td>

            <td>
              <button @click="Edit(item.id)" class="btn btn-sm btn-info mr-3">
                Edit
              </button>
              <button
                @click="deleteItem(item.id)"
                class="btn btn-sm btn-danger mr-3"
                style="cursor: pointer"
              >
                Delete
              </button>
              <button
                @click="setPassword(item.id, item.username)"
                class="btn btn-sm btn-success mr-3"
              >
                Set password
              </button>
            </td>
          </tr>
        </tbody>

        <tbody v-if="!loadingData && filteredUsers.length < 1">
          <tr>
            <td colspan="8" class="text-center">No record(s) found</td>
          </tr>
        </tbody>

        <tbody v-if="loadingData">
          <tr>
            <th class="text-center" colspan="10">
              <vDataLoader></vDataLoader>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import {
  FETCH_B_USER,
  DELETE_ORGANIZATION_USER,
  EMAIL_PASSWORD_B_USER,
} from "@/core/services/store/actions.type";
import { mapGetters } from "vuex";
import vDataLoader from "@/components/frontend/spinner.vue";
export default {
  data() {
    return {
      rules: [
        { message: "One lowercase letter required.", regex: /[a-z]+/ },
        { message: "One uppercase letter required.", regex: /[A-Z]+/ },
        { message: "8 characters minimum.", regex: /.{8,}/ },
        { message: "One number required.", regex: /[0-9]+/ },
        { message: "One special character required.", regex: /[!@#$%^&*]+/ },
      ],
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "",
        },
        {
          id: 2,
          title: "Setting",
          route: "",
        },
        {
          id: 3,
          title: "Users",
          route: "",
        },
      ],
      loadingData: false,
      searchInput: "",
    };
  },
  components: {
    vDataLoader,
  },
  computed: {
    ...mapGetters(["getUsers", "currentUser"]),
    filteredUsers() {
      let tempUser = this.getUsers;
      //process search input
      if (this.searchInput != "" && this.searchInput) {
        tempUser = tempUser.filter((item) => {
          return item.name
            .toUpperCase()
            .includes(this.searchInput.toUpperCase());
        });
      }
      return tempUser;
    },
  },
  created() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.getUserData();
  },
  methods: {
    async setPassword(id = 0, username = "") {
      const { value: password } = await this.$swal.fire({
        title: "Setting new password for user <b>" + username + "</b>",
        input: "password",
        inputPlaceholder: "Enter new password",
        confirmButtonText: "Save",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        showCloseButton: true,
        everseButtons: true,
        inputValidator: (value) => {
          if (!value) {
            return "Please enter password!";
          } else {
            for (let condition of this.rules) {
              if (!condition.regex.test(value)) {
                return condition.message;
              }
            }
          }
        },
      });
      if (password) {
        this.$store
          .dispatch(EMAIL_PASSWORD_B_USER, {
            user_id: id,
            password: password,
          })
          .then((data) => {
            this.$toastr.s(data.msg);
            this.$swal.fire(`New Password has been set for user ${username}`);
            // this.closeBtnLoad(ref);
          })
          .catch((err) => {
            this.errors = err;
            // console.log(this.errors);
            this.$toastr.e(this.errors.error.password);
            // this.toast.fire(`${this.errors.error.password}`);
            // this.closeBtnLoad(ref);
          });
      }
    },
    editRole(i) {
      this.filteredUsers[i].change_status = true;
      this.editrole = true;
    },
    Edit(id) {
      this.$router.push({
        name: "client.user.edit",
        params: { id: id },
      });
    },
    closeChangeStatusFlag(index) {
      this.selectedAppId = 0;
      this.filteredUsers[index].change_status = false;
    },

    loadingButton(ref) {
      const submitButton = this.$refs[ref];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },
    closeBtnLoad(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
      submitButton.disabled = false;
    },
    deleteItem(id) {
      this.$swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this User data!",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((willDelete) => {
        if (willDelete.isConfirmed) {
          this.$store.dispatch(DELETE_ORGANIZATION_USER, id).then((data) => {
            this.$toastr.s(data.msg);
            // this.filteredUsers.splice(index, 1);
            this.getUserData();
          });
        }
      });
    },

    getUserData() {
      // var _data = {
      //   is_client: this.currentUser.u.is_client,
      //   is_service_provider: this.currentUser.u.is_service_provider,
      // };
      this.loadingData = true;
      this.$store
        .dispatch(FETCH_B_USER)
        .then(() => {
          this.loadingData = false;
        })
        .catch(() => {
          this.loadingData = false;
        });
    },
  },
};
</script>

<style></style>
